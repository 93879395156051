@media (max-width:800px) {
    .nav {
        display: none;
        padding: 5px;
        align-items: center;
        background: rgb(240, 90, 37);
        grid-template-columns: 1fr 1fr 1fr;
    }

    .sections {
        display: none;
        align-self: center;
    }

    .langSelect {
        float: right;
    }

    .menuButton {

        background: #eee;
        border: 0;
        border-radius: 360;
        margin-left: 5px;
    }

    .mobileBar {
        background: #333;
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 0;

    }
}

@media (min-width:800px) {
    .nav {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 5px;
        align-items: "center";
        background: rgb(240, 90, 37);

    }

    .sections {
        display: flex;
        min-width: 56vw;
        max-width: 100%;
    }

    .menuButton {
        display: none;

    }

    .mobileBar {
        display: none;
    }

}