.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 600px;
}

.each-slide-effect span {
    padding: 40px;
    font-size: 20px;
    background: #efefef44;
    text-align: center;
}