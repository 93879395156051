@media (min-width:700px) {
    .sectorsGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .gridHolder {
        height: 500px;
    }
}

a {
    text-decoration: none;
}

@media (max-width:700px) {
    .sectorsGrid {
        grid-template-columns: 1fr;
    }

    .gridHolder {
        height: 1500px;
    }
}