.AboutPage {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 50;
}

.contactCards {
    transition-duration: 0.4s;
    display: grid;
    gap: 25px;
    margin-top: 50px;
    flex-direction: row;
    margin: 25px;
}

@media (min-width:700px) {
    .contactCards {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}


@media (max-width:700px) {
    .contactCards {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}

.card {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    border: 1px black solid;
    border-radius: 12px;
    box-shadow: 0px 0px 12px #0009;
    min-width: 150px;
    text-align: center;
    transition-duration: 0.4s;
}

.card:hover {
    background-color: #ececec;
}