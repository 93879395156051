@media (max-width: 800px) {
    .feldspatContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px;
    }
}

@media (min-width: 800px) {
    .feldspatContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 10px;

    }
}