.AboutPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.aboutBackground {
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    padding: 25px;
    font-style: oblique;
    font-family: serif;
    background-color: #f8f8f8;
    border: 1px solid #0004;
    margin: 24px;
    border-radius: 6px;
}